import React from "react"
import Seo from "../seo"
import Layout from "../layout"

export default function CustomInteriorPage({ body, seo }) {

  return (
    <Layout>
      <Seo title={seo} />
      {body}
    </Layout>
  )
}