/* This example requires Tailwind CSS v2.0+ */
import { graphql } from "gatsby"
import React from "react"
import CustomInteriorPage from "../components/page-layouts/custom-interior-page"
import {
  PageH1Header,
  PageH2Header,
  PageH3Header,
} from "../components/typography/page-headers"

const calendar = [
  { month: "Nov", event: "Trip to Eastern State Penitentiary" },
  { month: "Dec", event: "Snowtubing" },
  { month: "Jan", event: "Klondike Derby" },
  { month: "Feb", event: "Cold Weather Camping Weekend" },
  { month: "Mar", event: "Pilgrimage & Encampment Weekend" },
  { month: "Apr", event: "Scout Skills Camping Weekend" },
  { month: "May", event: "Scout Day at Knoebels" },
  { month: "Jun", event: "Sunfish Pond Camping Weekend" },
]

function PageData() {
  return (
    <div className="relative bg-gradient-to-r from-cranberry to-split-blue z-20 overflow-hidden">
      <div className="pt-12 pb-32 px-8 w-5/6 mx-auto">
        {/* Columns */}
        <div className="grid grid-cols-1 md:grid-cols-2 pt-12 gap-12">
          {/* Left Column */}
          <div className="relative">
            <PageH1Header text="Troop-B" tint={"light"} />
            <PageH2Header text="Get Your Son Involved!" tint={"light"} />
            <p className="text-off-white font-bold text-xl">
              Join Troop 146-B for an exciting boy-lead program that works!
              Through the BSA program, Scouts learn a variety of skills that
              prepare them to confront challenges through their life.
            </p>
            <p className="text-off-white font-bold text-xl">
              We believe every adventure helps us uncover a little more about
              ourselves. We build the foundations for humility and
              compassion—strengthening character through actions—to prepare
              youth for a lifetime of leadership.
            </p>

            <p className="text-off-white font-bold text-xl">
              Skills Learned and Opportunities in BSA Scouts:
            </p>
            <ul className="text-off-white text-xl font-aleo">
              <li>Leadership</li>
              <li>Work as a Team</li>
              <li>Weekend Camping Trips</li>
              <li>Outdoor Proficiency and Conservation</li>
              <li>Physical Fitness</li>
              <li>First Aid</li>
            </ul>
          </div>

          {/* Right Column */}

          <div className="relative">
            <div className="inlne-block bg-coffee p-4">
              <PageH3Header text={"Troop Info"} tint={"light"} />
              <p className="text-off-white mb-2">
                <span className="font-bold underline">Where We Meet: </span>
                <a
                  href="https://maps.app.goo.gl/6CcWrLPSUWHUyj6UA"
                  target="_blank"
                  rel="noreferrer"
                  className={"text-light-blue"}
                >
                  <span className="block font-normal">
                    First Presbyterian Church
                  </span>
                  <span>750 N Evans St, Pottstown, PA 19464</span>
                </a>
              </p>
              <p className="text-off-white">
                <span className="font-bold underline">When We Meet: </span>
                <span className="block font-normal">
                  Monday Nights, 7:00 - 8:30PM
                </span>
              </p>
              <p className="text-off-white">
                <span className="font-bold">Upcoming Activities: </span>
              </p>
              <ul className="text-off-white font-aleo">
                {calendar.map((event, eIndex) => {
                  return (
                    <li key={eIndex}>
                      <span className="font-bold">{event.month + ": "}</span>
                      <span>{event.event}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>

        {/* End of Columns */}
      </div>
    </div>
  )
}

export default function TroopBPage({ data }) {
  const siteTitle = data.site.siteMetadata.title

  return <CustomInteriorPage body={PageData()} seo={siteTitle} />
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
