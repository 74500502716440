import React from "react";

export function PageH1Header({ text, tint }) {
    return (
        <h1 className={(tint === 'dark' || tint === undefined ? "text-gray-800" : "text-off-white")  + " font-semibold text-6xl mb-6 tracking-wider"}>{text}</h1>
    )
}

export function PageH2Header({ text, tint }) {
    return (
        <h2 className={(tint === 'dark' || tint === undefined ? "text-gray-800" : "text-off-white")  + " font-normal text-4xl mb-6 tracking-wide"}>{text}</h2>
    )
}

export function PageH3Header({ text, tint }) {
    return (
        <h3 className={(tint === 'dark' || tint === undefined ? "text-gray-800" : "text-off-white") + " font-light text-3xl mb-6 tracking-wide"}>{text}</h3>
    )
}

export function PageH5Header({ text, tint }) {
    return (
        <h5 className={(tint === 'dark' || tint === undefined ? "text-gray-800" : "text-off-white") + " font-semibold text-xl mb-2"}>{text}</h5>
    )
}